@font-face {
  font-family: "CircularStd-Bold";
  src: url("../fonts/CircularStd-Bold.otf");
  font-style: normal;
}

@font-face {
  font-family: "CircularStd-Book";
  src: url("../fonts/CircularStd-Book.otf");
  font-style: normal;
}

@font-face {
  font-family: "Garamond Premier Pro Regular";
  src: url("../fonts/GaramondPremrPro.otf");
  font-style: normal;
}
@font-face {
  font-family: "Breathing";
  src: url("../fonts/Breathing.ttf");
  font-style: normal;
}

@import "./utils/utils.scss";
@import "./pages/form-design.scss";
@import "./pages/error-modal.scss";

.app.container-fluid {
  min-height: 100vh;
  padding: 24px 16px 0;
  > header {
    .logo {
      width: 193px;
      height: 30.95px;
      cursor: pointer;

      @media (max-width: 767px) {
        width: 93px;
        max-height: 14.91px;
      }
    }
    > .container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .btn-translate {
        outline: none;
        border: none;
        background-color: transparent;
      }
    }
  }
  > main {
    min-height: calc(100vh - 240px);
    padding-top: 20px;

    h1 {
      font-size: 24px;
      font-family: $titleFont;
    }
    h2 {
      font-size: 20px;
      font-family: $titleFont;
    }
    h3 {
      font-size: 18px;
      font-family: $titleFont;
    }
    h4 {
      font-size: 16px;
      font-family: $titleFont;
    }
    h5 {
      font-size: 14px;
      font-family: $titleFont;
    }
    h6 {
      font-style: 12px;
      font-family: $titleFont;
    }
  }
  > footer {
    margin: 0 -16px;
    padding: 16px 16px 0;
    background-color: #000;
    color: #fff;

    .desktop-content {
      max-width: 1140px;
      width: 100%;
    }

    .footer__top {
      display: flex;

      @media (min-width: 600px) {
        justify-content: space-between;
        align-items: center;
      }

      .footer__top__left {
        width: 19px;
        font-size: 32px;
        margin-right: 12px;
        height: auto;

        img {
          position: relative;
          height: 32px;
          width: 19px;
          top: -5px;
        }

        @media (min-width: 600px) {
          img {
            height: 45px;
            width: 26px;
          }
        }
      }
      .footer__top__center {
        display: flex;
        justify-content: space-between;
        flex: 0.5 1 auto;
      }

      .footer__top__right {
        font-size: 10px;
        line-height: 13px;
      }
    }
    .footer__top--desktop {
      font-size: 16px;
      font-family: $textFont;

      .title {
        font-size: 14px;
        font-family: $textFontBold;
      }
    }

    .footer__bottom {
      text-align: center;
      font-size: 8px;
      margin-top: 20px;
      padding-bottom: 10px;
      color: #979797;
    }

    .footer__bottom--desktop {
      font-size: 10px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-top: 35px;
      gap: 8px;

      > div:first-child {
        font-size: 12px;
        color: #fff;
      }
    }

    @media (min-width: 600px) {
      display: flex;
      justify-content: center;
      padding: 35px;
    }
  }

  img {
    object-fit: cover;
    object-position: center;
  }

  input::placeholder {
    color: #979797;
  }

  .title-font {
    font-family: $titleFont;
  }
  .text-font {
    font-family: $textFont;
  }
  .text-font-bold {
    font-family: $textFontBold;
  }

  button {
    font-family: $textFontBold;
  }

  .btn-deault {
    height: 26px;
    width: max-content;
    background-color: #000000;
    border: none;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;

    &:hover:not(:disabled) {
      background-color: #000;
      color: #fff;
    }

    svg {
      font-size: 18px;
      color: #fff;
    }

    @media (min-width: 600px) {
      height: 35px;
      padding: 0 12px;
    }
  }
}
