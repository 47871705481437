.error-modal {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;

	.main {
		width: 350px;
		padding: 48px 32px;
		background-color: #fff;
		overflow-y: auto;
		overflow-x: hidden;
		max-height: calc(100% - 100px);
		border-radius: 8px;

		.title {
			font-family: $titleFont;
			text-align: center;
			font-size: 24px;
			margin-bottom: 24px;
		}

		.content {
			text-align: center;
			font-size: 14px;
			margin-bottom: 24px;
			font-family: $textFont;
			color: #202020;

			@media (min-width: 600px) {
				font-size: 16px;
			}
		}

		button.btn-close {
			height: 35px;
			width: max-content;
			background-color: #fff;
			border: 1px solid #000000;
			color: #000;
			border-radius: 8px;
			font-size: 16px;
			padding: 0 16px;

			&:hover {
				background-color: #000;
				color: #fff;
			}
		}
	}
}
